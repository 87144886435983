<template>
  <div class="mainPage">
    <p id="headPage" v-if="ReportTypeId==1">Neraca</p>
    <p id="headPage" v-if="ReportTypeId==2">Laporan Operasional</p>
    
    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Laporan</p>
          <b-select v-model="ReportTypeId"  @change="this.changeReportType">
            <option value="2">Lap. Operasional</option>
            <option value="1">Neraca</option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Bulan</p>
          <b-select v-model="month"  @change="this.getAmount">
            <option value="">Seluruh Bulan</option>
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </b-select>
        </div>

        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Periode</p>
          <b-select v-model="PeriodId" @change="this.getGroup">
            <option v-for="e in this.periods" :key="e.id" :value="e.id">{{ e.year }} </option>
          </b-select>
          <!-- <p id="keterangan">*) Periode Wajib dipilih</p> -->
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Panitia/Pengurus</p>
          <b-select v-model="GroupId" @change="this.getGroup">
            <option v-for="e in this.groups" :key="e.id" :value="e.id">{{ e.name }} </option>
          </b-select>
        </div>
      </div>
    </div>
    <div id="titleRow">
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
      <button @click.prevent="tableHtmlToExcel('tableData')" id="printer">
          <img src="../assets/printer.png" id="printer" alt="">
      </button>
    </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Mata Anggaran</th>
            <th>Tahun {{ this.year }}</th> 
            <!-- <th>Tahun Sebelumnya</th> -->
          </tr>
        </thead>
        <tbody>
          <template v-for="e in this.mak">
            <tr >
              <td id="kelompok">{{e.name}} </td>
              <td class="uang" id="kelompok"> {{ formatUang(filterAmount(e.id, 0,0)) }} </td>
              <!-- <td class="uang" id="kelompok"> {{ formatUang(filterLastAmount(e.id, 0,0)) }} </td> -->
            </tr>
            <template v-for="x in e.MataAnggarans"> 
              <tr>
                <td id="ma">{{ x.coa }} {{ x.name }}</td>
                <td class="uang" id="ma"> {{ formatUang(filterAmount(0, x.id,0)) }} </td>
                <!-- <td class="uang" id="ma"> {{ formatUang(filterLastAmount(0, x.id,0)) }} </td> -->
              </tr>
              <template v-for="y in x.SubMataAnggarans"> 
                <tr>
                  <td id="subma">{{ y.coa }} {{ y.name }}</td>
                  <td class="uang" id="subma"> {{ formatUang(filterAmount(0,0,y.id)) }} </td>
                  <!-- <td class="uang" id="ma"> {{ formatUang(filterLastAmount(0, x.id,0)) }} </td> -->
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </table>

    </div>

    <!-- modal -->

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'

export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      BranchId: 0,
      ReportTypeId: 1,
      ProjectId: 0,
      mak:[],
      dataTable: [],
      groups: [],
      year: moment(new Date()).format("YYYY"),
      month: "",
      isLoading : false,

      kelmaNow: [],
      maNow: [],
      submaNow: [],
      
      kelmaLast: [],
      maLast: [],
      submaLast: [],

      cekKasNow: 0,
      PeriodId: 1,
      GroupId: 0

      // filter

    
    }
  },
  created() {

    this.getMak()
    this.getAmount()
    this.getGroup()
    this.$store.dispatch("getPeriod")
  },
  computed: {
    role() {
      return localStorage.getItem("role");
    },
    periods() {
      return this.$store.state.periods
    }
  },
    // components: { datePicker },  
  methods: {
    resetFilter() {
      this.BranchId= 0
      this.ProjectId= 0
      this.keyword= ""
      this.month= ""
      this.getAmount()
    },
    cekKas() {
      let data = []
      data = this.submaNow.find(e => e.SubMataAnggaranId == 29)
      
      return data
    },
    changeReportType() {
      this.getMak()
      this.getAmount()
    },
    async getGroup() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/user/group",
          headers: {
            access_token: localStorage.getItem("access_token"),
          },
          params: {
            PeriodId: this.PeriodId,
          }
        })
        this.groups = data
        this.getAmount()
        let groupId = this.groups.find(e => e.id == this.GroupId)
        if(!groupId) this.GroupId = 0
        // this.totalBalance = data.totalBalance
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/debt/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            BranchId: this.BranchId,
            DebtVendorId: this.findDebtVendorId, 
            DebtTypeId: this.findDebtTypeId, 
            ProjectId: this.ProjectId, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'Neraca',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    
    tableHtmlToExcel(tableID,filename = `Laporan Keuangan Tahunan`   ) {
        // Download Transaksi
        var downloadLink;
        var dataType = "application/vnd.ms-excel";
        var tableSelect = document.getElementById(tableID);
        var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

        filename = filename ? filename + ".xls" : "excel_data.xls";

        downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
          var blob = new Blob(["\ufeff", tableHTML], {
            type: dataType
          });
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          downloadLink.href = "data:" + dataType + ", " + tableHTML;

          downloadLink.download = filename;

          downloadLink.click();
        }
    },
    formatUang(data) {
      // console.log(data);
			let uang = "";
			data = data.toString();
			for (let i = 0; i < data.length; i++) {
				if ((data.length - i) % 3 == 0 && i !== 0) {
				uang += `.${data[i]}`;
				} else {
				uang += data[i];
				}
			}
      return uang
    },
    async getMak() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/report/mak",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            ReportTypeId: this.ReportTypeId,
          }
        })
        this.mak = data
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async getAmount() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/report/amount",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            ReportTypeId: this.ReportTypeId,
            month: this.month,
            PeriodId: this.PeriodId,
            GroupId: this.GroupId,
          }
        })
        console.log(data,"-----------");
        
        this.kelmaNow = data.kelma
        this.maNow = data.ma
        this.submaNow = data.subma
        console.log(data,"data");
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },

    filterAmount(kel,ma,sub) {
      if(kel !== 0) {
        return this.kelmaNow.find(e => e.KelompokMaId == kel)?.amount || 0
      } else if(ma !== 0) {
        return this.maNow.find(e => e.MataAnggaranId == ma)?.amount || 0
      } else if(sub !== 0) {
        return this.submaNow.find(e => e.SubMataAnggaranId == sub)?.amount || 0
      }
    },
    filterLastAmount(kel,ma,sub) {
      if(kel !== 0) {
        return this.kelmaLast.find(e => e.KelompokMaId == kel)?.amount || 0
      } else if(ma !== 0) {
        return this.maLast.find(e => e.MataAnggaranId == ma)?.amount || 0
      } else if(sub !== 0) {
        return this.submaLast.find(e => e.SubMataAnggaranId == sub)?.amount || 0
      }
    },

    async getDataTable() {
      // this.isLoading = true
      // try {
        
      //   let {data} = await axios({
      //     method: "GET",
      //     url: "/database/balance",
      //     headers: {
      //       access_token: localStorage.getItem("access_token")
      //     },
      //     params: {
      //       ReportTypeId: this.ReportTypeId,
      //       BranchId: this.BranchId,
      //       ProjectId: this.ProjectId, 
      //     }
      //   })

      //   this.isLoading = false
      // } catch(err) {
      //   this.isLoading = false
      //   console.log(err);
      // }

    },
    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/debt/balance/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId: this.BranchId,
            DebtVendorId: this.findDebtVendorId, 
            DebtTypeId: this.findDebtTypeId, 
            ProjectId: this.ProjectId, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'Saldo Piutang Krayawan / Dana gantung',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }
  #tableData td {
    font-size: 11px;
    padding: 3px 3px 3px auto;
  }
  #kelompok {
    font-size: 12px;
    background-color: antiquewhite;
    font-weight: 800;
  }
  #ma{
    font-weight: bold;
    padding-left: 12px;
  }
  #subma{
    padding-left: 24px;
  }

  p#keterangan {
    color: #e96d6d;
    text-align: left;
    font-weight: 500;
    font-size: 0.7em;
    font-style: italic
  }


</style>