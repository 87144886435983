<template>
  <div class="dropdown-container">
    <h1>{{ selected ? selected.text : "Select an option" }}</h1>
    <p>Selected ID: {{ getId }}</p>
    <div @click="toggleDropdown" class="dropdown-header">
      {{ selected ? selected.text : "Select an option" }}
    </div>
    <div v-if="open" class="dropdown">
      <!-- Input berada di dalam dropdown untuk pencarian -->
      <input
        type="text"
        v-model="search"
        placeholder="Type to search..."
        class="dropdown-search"
      />
      <div
        v-for="option in filteredOptions"
        :key="option.id"
        @click="select(option)"
        class="dropdown-item"
      >
        {{ option.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      selected: null,
      getId: 0,
      search: "",
      options: Array.from({ length: 100 }, (v, i) => ({
        id: i + 1,
        text: `Option`
      }))
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter(option =>
        option.text.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  },
  watch: {
    search(newSearch) {
      const matchedOption = this.options.find(option =>
        option.text.toLowerCase() === newSearch.toLowerCase()
      );
      if (matchedOption) {
        // Jika ada kecocokan, set getId ke id dari opsi yang cocok
        this.getId = matchedOption.id;
      } else {
        // Jika tidak ada kecocokan, set getId ke 0
        this.getId = 0;
      }
    }
  },
  methods: {
    toggleDropdown() {
      this.open = !this.open;
      if (!this.open) this.search = ""; // Reset search when closing dropdown
    },
    select(option) {
      this.selected = option;
      this.getId = option.id;
      this.open = false;
      this.search = option.text; // Set search to selected option text
    },
    closeDropdown() {
      this.open = false;
    },
    handleClickOutside(event) {
      const dropdownContainer = this.$el.querySelector(".dropdown-container");
      if (dropdownContainer && !dropdownContainer.contains(event.target)) {
        this.closeDropdown();
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>

<style>
.dropdown-container {
  position: relative;
  width: 300px;
}
.dropdown-header {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: #fff;
}
.dropdown {
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 1;
  margin-top: 5px;
  padding: 5px;
}
.dropdown-search {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 5px;
  border: 1px solid #ddd;
}
.dropdown-item {
  padding: 10px;
  cursor: pointer;
}
.dropdown-item:hover {
  background-color: #eee;
}
</style>
