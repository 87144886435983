<template>
    <div class="Navbar">
      <p id="titleNavbar">PKKPNUP</p>
      <div class="rightSide">
        <button @click.prevent="toggleMenu" :class="{ 'active': isMenuVisible }">
          <img src="../assets/user.png" id="userImg" alt="" />
          <ul v-show="isMenuVisible" class="user-menu">
            <li @click.prevent="showModalPassword">Password</li>
            <li @click.prevent="logout">Logout</li>
          </ul>
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Navbar",
    data() {
      return {
        isMenuVisible: false,
        password: "",
      };
    },
    methods: {
      toggleMenu() {
        this.isMenuVisible = !this.isMenuVisible;
      },
      showModalPassword() {
      alert("Yess")
        // No need to close the menu here as it's not a modal anymore
        this.isPasswordModalVisible = true; // Assuming you still have a password modal
        this.password = ""; // Clear password field on modal open
      },
      logout() {
        // Your logout logic here
        this.$store.commit("setIsLogin", false);
        localStorage.clear();
        this.$router.push("/login");
      },
      // ... other methods (e.g., changePassword)
    },
  };
  </script>
  
  <style scoped>
  .Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;
    top: 0;
    background-color: #6EACDA;
    border-bottom: #a1a9a9 1px solid;
  }
  
  #titleNavbar {
    font-size: 20px;
    font-weight: bold;
    color: #faf9f9;
    margin: 0;
  }
  
  #userImg {
    width: 24px;
    height: 24px;
  }
  
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative; /* Make the button relative for positioning */
    display: flex; /* Allow for aligning the icon and menu */
    align-items: center; /* Center the content vertically */
  }
  
  .user-menu {
    /* list-style: none; */
    padding: 0;
    margin: 0;
    position: absolute; /* Position menu relative to the button */
    top: 100%; /* Position menu below the button */
    right: 0; /* Position menu at the right of the button (adjust if needed) */
    background-color: #fff;
    /* box-shadow: 0 2px 5px rgba(165, 9, 9, 0.1); */
    display: none; /* Initially hide the menu */
  }
  
  button:hover .user-menu {
    display: block; /* Show the menu on hover */
  }
  
  .user-menu li {
    padding: 4px;
    font-size: 12px;
    /* cursor: pointer; */
  }

  .user-menu li:hover {
    background-color: #ebebeb;
  }
  </style>
  