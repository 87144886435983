this.$store.dispatch("getPeriod")<template>
  <div class="mainPage">
    <p id="headPage">Database Akun</p>
    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input type="text" v-model="keyword"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Mata Anggaran</p>
            <b-select v-model="findMataAnggaranId" @change="this.getDataTable" required>
              <option v-for="e in this.mas" :key="e.id" :value="e.id">{{ e.ReportType.name }} - {{ e.KelompokMa.name }} - {{ e.name }}</option>
            </b-select>
        </div>
      </div>
    </div>
    <div id="titleRow">
        <b-button class="mr-2" id="addModal" @click="showAdd" >Tambah Sub MataAnggaran</b-button>
        <b-button id="addModal" @click="showAddMataAnggaran" >Tambah mata Anggaran</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>ID</th>
            <th>Laporan</th>
            <th>Kelompok Mata Anggaran</th>
            <th>Mata Anggaran</th>
            <th>Name</th>
            <th>Saldo Normal</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(e,index) in this.dataTable" :key="e.id">
            <td>{{ index+1 }}</td>
            <td>{{ e.ReportType.name }}</td>
            <td>{{ e.KelompokMa.name }}</td>
            <td>{{ e.MataAnggaran.name }} - {{ e.MataAnggaran.coa }}</td>
            <td>{{ e.coa }} - {{ e.name }}</td>
            <td v-if="e.normalBalanceId == 1">DEBET</td>
            <td v-if="e.normalBalanceId == 2"> KREDIT</td>
            <td id="action" class="text-center" >
              <button @click.prevent="confirmDelete(e.id)" title="Hapus"  >
                <img src="../assets/trash.png" alt="">
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal Sub Ma -->
    <div class="modal" id="mainModal">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Akun</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" >&times; </span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="isEdit ? editData() : addData()">
            <div class="inRow">
              <div class="inCol">
                <p id="titleModal">Mata Anggaran</p>
                <b-select v-model="MataAnggaranId" required>
                  <option v-for="e in this.mas" :key="e.id" :value="e.id">{{ e.ReportType.name }} - {{ e.KelompokMa.name }} - {{ e.name }} - {{ e.coa }}</option>
                </b-select>
              </div>
            </div>
            <div class="inRow">
              <div class="inCol">
                <p id="titleModal">Saldo Normal</p>
                <b-select v-model="normalBalanceId" required>
                  <option v-for="e  in this.normalBalance" :key="e.id" :value="e.id">{{ e.name }}</option>
                </b-select>
              </div>
            </div>
            <div class="inRow">
              <div class="inCol">
                <p id="titleModal">COA</p>
                <b-input v-model="coa" type="text" required></b-input>
              </div>
            </div>
            <div class="inRow">
              <div class="inCol">
                <p id="titleModal">Nama</p>
                <b-input v-model="name" type="text" required></b-input>
              </div>
            </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary">Tambah</button>
          </div>
          </form>
          </div>
        </div>
    </div>

    <!-- modal Mata Anggaran -->
    <div class="modal" id="modalMa">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Mata Anggaran</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" >&times; </span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="isEdit ? editData() : addMataAnnggaran()">
            <div class="inRow">
              <div class="inCol">
                <p id="titleModal">Kelompok Mata Anggaran</p>
                <b-select v-model="addMa.KelompokMaId" required>
                  <option v-for="e in this.kelmas" :key="e.id" :value="e.id">{{ e.ReportType.name }} -- {{ e.name }} - ({{ e.coa }})</option>
                </b-select>
              </div>
            </div>
            <div class="inRow">
              <div class="inCol">
                <p id="titleModal">COA</p>
                <b-input v-model="addMa.coa" type="text" required></b-input>
              </div>
            </div>
            <div class="inRow">
              <div class="inCol">
                <p id="titleModal">Nama</p>
                <b-input v-model="addMa.name" type="text" required></b-input>
              </div>
            </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary">Tambah</button>
          </div>
          </form>
          </div>
        </div>
    </div>
    
  <!--  -->
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {
      jurnal: {},
      dataTable: [],
      kelmas: [],
      normalBalance: [
        { id: 1, name: 'Debet' },
        { id: 2, name: 'Kredit' }
      ],
      currentPage: 1,
      keyword: '',
      perPage: 40,
			totalPage : 1,
      isLoading: false,
      isEdit: false,
      name: '',
      type: 1,
      MataAnggaranId: 0,
      normalBalanceId: 1,
      // find
      findKelompokMaId: 0,
      findMataAnggaranId: 0,
      keyword: '',
      addMa: {
        name: '',
        KelompokMaId: 0,
        coa: ''
      }

    }
  },
  created() {
    this.getDataTable()
    this.getKelma()
    this.$store.dispatch("getKelompok")
    this.$store.dispatch("getMataAnggaran")
  },
  computed: {

    role() {
      return localStorage.getItem("role");
    },
    periods() {
      return this.$store.state.periods
    },
    kelompoks() {
      return this.$store.state.kelompoks
    },
    mas() {
      return this.$store.state.mataAnggarans
    }
  },  
  methods: {
    resetFilter() {
      this.endDate= ""
      this.startDate= ""
      this.keyword= ""
      this.findPeriodId= 0
      this.getDataTable()
    },
    showAdd() {
      // return alert("Yess")
      // this.$refs.mainModal.show();
      $('#mainModal').modal('show')
    },
    showAddMataAnggaran() {
      $('#modalMa').modal('show')
    },
    showEdit(e) {
      this.KelompokMaId = 
      $('#mainModal').modal('show')
    },
    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/database/subma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            keyword: this.keyword,
            KelompokMaId: this.findKelompokMaId,
            MataAnggaranId: this.findMataAnggaranId
          }
        })
        this.dataTable = data
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },
    async getKelma() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/database/kelma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.kelmas = data
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },

    async addData() {
      this.isLoading = true
      try {
        let {data} = await axios({
          method: "post",
          url: "/database/subma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          data: {
            coa: this.coa,
            name: this.name,
            MataAnggaranId: this.MataAnggaranId,
            normalBalanceId: this.normalBalanceId,
          }
        })
        $('#mainModal').modal('show')
        this.getDataTable()
        this.isLoading = false
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
        console.log(err);
        this.isLoading = false
      }
    },


    async addMataAnnggaran() {
      this.isLoading = true
      try {
        let {data} = await axios({
          method: "post",
          url: "/database/ma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          data: this.addMa
        })
        $('#modalMa').modal('show')
        this.$store.dispatch("getMataAnggaran")
        this.isLoading = false
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
        console.log(err);
        this.isLoading = false
      }
    },

    async deleteData(id) {
      
      try {
        
        let data =await  axios.delete(`/database/ma/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.getDataTable()

      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
          Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
        }     
      } catch(err) {
        
        Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }
  .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  padding: 20px 250px !important;
}



</style>