import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginPage from "../views/LoginPage.vue"
import Voucer from "../views/Voucer.vue"
import Neraca from "../views/Neraca.vue"
import User from "../views/User.vue"
import Group from "../views/Group.vue"
import Period from "../views/Period.vue"
import AddJurnal from "../views/AddJurnal.vue"
import Jurnal from "../views/Jurnal.vue"
import BukuBesar from "../views/BukuBesar.vue"
import ClosePeriod from "../views/ClosePeriod.vue"
import Akun from "../views/Akun.vue"
import Test from "../views/Test.vue"

import Swal from "sweetalert2";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { requiresAuth2: true }

  },
  {
    path: '/voucer',
    name: 'voucer',
    component: Voucer,
    meta: { requiresAuth: true }
  },
  {
    path: '/neraca',
    name: 'neraca',
    component: Neraca,
    meta: { requiresAuth: true }
  },
  {
    path: '/addJurnal',
    name: 'addJurnal',
    component: AddJurnal,
    meta: { requiresAuth: true }
  },
  {
    path: '/jurnal',
    name: 'jurnal',
    component: Jurnal,
    meta: { requiresAuth: true }
  },
  {
    path: '/bukubesar',
    name: 'bukubesar',
    component: BukuBesar,
    meta: { requiresAuth: true }
  },
  {
    path: '/verifikasi',
    name: 'verifikasi',
    component: ClosePeriod,
    meta: { requiresAuth: true }
  },
  {
    path: '/akun',
    name: 'akun',
    component: Akun,
    meta: { requiresAuth: true }
  },
  {
    path: '/period',
    name: 'period',
    component: Period,
    meta: { requiresAuth: true }
  },
  {
    path: '/group',
    name: 'group',
    component: Group,
    meta: { requiresAuth: true }
  },
  {
    path: '/user',
    name: 'user',
    component: User,
    meta: { requiresAuth: true }
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => { // pengecekan meta required
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('access_token')) {
      Swal.fire("Maaf", "Anda Harus Login Terlebih dahulu", "error");
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

router.beforeEach((to, from, next) => { // pengecekan meta required
  if (to.matched.some(record => record.meta.requiresAuth2)) {
    if (localStorage.getItem('access_token')) {
      Swal.fire("Semangat Pagi", "Semangat Pagi dan Semangat Bekerja", "success");
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
