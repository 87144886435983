import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../API/axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    selectedJurnal: {},
    periods: [],
    kelompoks: [],
    mataAnggarans: []
  },
  getters: {
  },
  mutations: {
    setIsLogin(state, data) {
      state.isLogin = data
    },
    setPeriods(state, data) {
      state.periods = data
    },
    setJurnal(state, data) {
      state.selectedJurnal = data
    },
    getKelompok(state, data) {
      state.kelompoks = data
    },
    getMas(state, data) {
      state.mataAnggarans = data
    },
  },
  actions: {
    getLocalStorage(context) {
      if(localStorage.getItem("access_token")) {
        context.commit('setIsLogin', true)
      }
    },
    getJurnal(context, data) {
      context.commit('setJurnal', data)
    },
    async getPeriod(context) {

      try {

        let {data} = await axios({
          method: 'get',
          url: '/user/period',
          headers: {
            access_token: localStorage.getItem("access_token")
          }
        })
        context.commit('setPeriods', data)
      } catch(err) {
        console.log(err);
      }
    },
    async getKelompok(context) {

      try {

        let {data} = await axios({
          method: 'get',
          url: '/database/kelma',
          headers: {
            access_token: localStorage.getItem("access_token")
          }
        })
        context.commit('getKelompok', data)
      } catch(err) {
        console.log(err);
      }
    },
    async getMataAnggaran(context) {

      try {
        let {data} = await axios({
          method: 'get',
          url: '/database/ma',
          headers: {
            access_token: localStorage.getItem("access_token")
          }
        })
        console.log('masuh get ma store', data);
        context.commit('getMas', data)
      } catch(err) {
        console.log(err);
      }
    },
  },
  modules: {
  }
})
