this.$store.dispatch("getPeriod")<template>
  <div class="mainPage">
    <p id="headPage">Database User</p>
    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input type="text" v-model="keyword"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Periode</p>
          <b-select v-model="findPeriodId" @change="this.getGroup" required>
            <option v-for="e in this.periods" :key="e.id" :value="e.id">{{ e.year }}</option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Pengurus / Pannitia</p>
          <b-select v-model="findGroupId" @change="this.getDataTable" required>
            <option v-for="e in this.groups" :key="e.id" :value="e.id">{{ e.name }}</option>
          </b-select>
        </div>
      </div>
    </div>
    <div id="titleRow">
        <b-button id="addModal" @click="showAdd" >Tambah</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>#</th>
            <th>Periode</th>
            <th>Pengurus/Panitia</th>
            <th>Username</th>
            <th>Role</th>
            <!-- <th colspan="2">Aksi</th> -->
            <th >Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(e,index) in this.dataTable" :key="e.id">
            <td>{{ index+1 }}</td>
            <td>{{ e.Period.year }}</td>
            <td>{{ e.Group.name }}</td>
            <td>{{ e.username }}</td>
            <td>{{ e.role }}</td>
            <!-- <td>{{ e.type }}</td>
            <td>{{ e.detail }}</td> -->
            <!-- <td class="uang"> {{ formatUang(e.amount)}} </td> -->
            <td id="action" class="text-center" >
                <!-- <button @click.prevent="showEdit(e)" title="Edit" > -->
                <button @click.prevent="getOneJurnal(e.id)" title="Edit" >
                  <img src="../assets/edit.png" alt="">
                </button>
                <button @click.prevent="confirmDelete(e.id)" title="Hapus"  >
                  <img src="../assets/trash.png" alt="">
                </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
    <b-modal ref="mainModal" id="mainModal" title="Database User" @ok="addData">
      <form>
        <div class="inRow">
          <div class="inCol">
            <p id="titleModal">Username</p>
            <b-input v-model="username" type="text" required></b-input>
          </div>
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="titleModal">Password</p>
            <b-input v-model="password" minlength="6" type="text" required></b-input>
          </div>
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="titleModal">Role</p>
            <b-select v-model="role" required>
              <option v-for="(e,index) in this.roles" :key="index" :value="e">{{ e }}</option>
            </b-select>
          </div>
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="titleModal">Periode</p>
            <b-select v-model="PeriodId" required @change="this.getGroup">
              <option v-for="e in this.periods" :key="e" :value="e.id">{{ e.year }}</option>
            </b-select>
          </div>
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="titleModal">Pengurus/Panitia</p>
            <b-select v-model="GroupId" required>
              <option v-for="e in this.groups" :key="e.id" :value="e.id">{{ e.name }}</option>
            </b-select>
          </div>
        </div>
      </form>
      <!-- <b-button class="btn btn-secondary" @click="$refs.mainModal.hide()">Close</b-button> -->
    </b-modal>
    
  <!--  -->
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {
      jurnal: {},
      dataTable: [],
      groups: [],
      roles: ['bendahara', 'verifikator', 'anggota'],
      currentPage: 1,
      keyword: '',
      perPage: 40,
			totalPage : 1,
      isLoading: false,
      isEdit: false,
      username: '',
      PeriodId: 0,
      GroupId: 0,
      password: '',
      role: '',

      // find
      findGroupId: 0,
      findRole: 0,
      findPeriodId: 0,

    }
  },
  created() {
    this.getDataTable()
    this.$store.dispatch("getPeriod")
  },
  computed: {

    role() {
      return localStorage.getItem("role");
    },
    periods() {
      return this.$store.state.periods
    },
  },  
  methods: {
    resetFilter() {
      this.endDate= ""
      this.startDate= ""
      this.keyword= ""
      this.getDataTable()
    },
    showAdd() {
      // return alert("Yess")
      this.$refs.mainModal.show();
      // $('#mainModal').modal('show')
    },
    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/user",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            page: this.currentPage,
            limit: this.perPage,
            keyword: this.keyword,
            PeriodId: this.PeriodId,
            GroupId: this.GroupId,
          }
        })
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async getGroup() {

      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/user/group",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            PeriodId: this.PeriodId,
          }
        })
        this.groups = data
        this.getDataTable()
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    async addData() {
      this.isLoading = true
      try {
        let {data} = await axios({
          method: "post",
          url: "/user",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          data: {
            username: this.username,
            password: this.password,
            role: this.role,
            PeriodId: this.PeriodId,
            GroupId: this.GroupId,
          }
        })
        this.getDataTable()
        this.isLoading = false
      } catch(err) {
        console.log(err);
        this.isLoading = false
      }
    },
    cekStatus(data) {
      let result = ""
      if(data == 2) {
        result = 'By Planing'
      } else if(data == 1) {
        result= 'Urgent'
      }
      return result
    },
    formatUang(data) {
      // console.log(data);
			let uang = "";
			data = data.toString();
			for (let i = 0; i < data.length; i++) {
				if ((data.length - i) % 3 == 0 && i !== 0) {
				uang += `.${data[i]}`;
				} else {
				uang += data[i];
				}
			}
      return uang
    },

    async deleteData(id) {
      
      try {
        
        let data =await  axios.delete(`/jurnal/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.getDataTable()

      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
          Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
        }     
      } catch(err) {
        
        Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }
  .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



</style>