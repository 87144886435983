this.$store.dispatch("getPeriod")<template>
  <div class="mainPage">
    <p id="headPage">Daftar Tutup Buku</p>
    <!-- {{ dataTable }} -->
    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Periode</p>
            <b-select v-model="findPeriodId" @change="this.getDataTable" required>
              <option v-for="e in this.periods" :key="e.id" :value="e.id">{{ e.year }}</option>
            </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tipe</p>
            <b-select v-model="type" @change="this.getDataTable" required>
              <option v-for="e in this.types" :key="e.id" :value="e.id">{{ e.name }}</option>
            </b-select>
        </div>
      </div>
    </div>
    <!-- <div id="titleRow">
        <b-button id="addModal" @click="showAdd" >Tambah</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
    </div> -->
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>#</th>
            <th>Periode</th>
            <th>Bulan</th>
            <th>Pangurus / Kesek</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(e,index) in this.dataTable" :key="e.id">
            <td>{{ index+1 }}</td>
            <!-- <td>{{ e.month }}</td> -->
            <td>{{ e.Period.year }}</td>
            <td>{{ e.month ? cekMonth(e.month) : '' }}</td>
            <td>{{ e.Group.name }}</td>
            <td class="text-center">
              <button @click.prevent="changeStatus(e)">
                <img v-if="e.status" src="../assets/check.png" alt="">
                <img v-if="!e.status" src="../assets/wrong.png" alt="">
              </button>
            </td>
            <td id="action" class="text-center" >
                <!-- <button @click.prevent="showEdit(e)" title="Edit" > -->
                <button @click.prevent="confirmDelete(e.id)" title="Hapus"  >
                  <img src="../assets/trash.png" alt="">
                </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
  
  <!--  -->
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {
      jurnal: {},
      dataTable: [],
      roles: ['bendahara', 'verifikator', 'anggota'],
      types: [
        { id: 1, name: 'Pengurus' },
        { id: 3, name: 'Kesekretariatan' },
      ],
      months: [
        { id: '01', name: 'Januari' },
        { id: '02', name: 'Februari' },
        { id: '03', name: 'Maret' },
        { id: '04', name: 'April' },
        { id: '05', name: 'Mei' },
        { id: '06', name: 'Juni' },
        { id: '07', name: 'Juli' },
        { id: '08', name: 'Agustus' },
        { id: '09', name: 'September' },
        { id: '10', name: 'Oktober' },
        { id: '11', name: 'November' },
        { id: '12', name: 'Desember' }
      ],
      currentPage: 1,
      keyword: '',
      perPage: 40,
			totalPage : 1,
      isLoading: false,
      isEdit: false,
      name: '',
      PeriodId: 0,
      GroupId: 0,
      type: 1,

      // find
      findPeriodId: 0,

    }
  },
  created() {
    this.getDataTable()
    this.$store.dispatch("getPeriod")
  },
  computed: {

    role() {
      return localStorage.getItem("role");
    },
    periods() {
      return this.$store.state.periods
    },
  },  
  methods: {
    resetFilter() {
      this.endDate= ""
      this.startDate= ""
      this.keyword= ""
      this.findPeriodId= 0
      this.getDataTable()
    },
    showAdd() {
      // return alert("Yess")
      // this.$refs.mainModal.show();
      $('#mainModal').modal('show')
    },
    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/user/closeperiod",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            page: this.currentPage,
            limit: this.perPage,
            PeriodId: this.findPeriodId,
            type : this.type
          }
        })
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    async changeStatus(e) {
      
      try {
        this.isLoading = true
        let data2 = await axios({
          method: "put",
          url: `/user/closeperiod/status/${e.id}`,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.getDataTable()
        Swal.fire('Berhasil', 'Data berhasil diproses', 'success');
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async addData() {
      this.isLoading = true
      try {
        let {data} = await axios({
          method: "post",
          url: "/user/group",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          data: {
            PeriodId: this.PeriodId,
            name: this.name,
          }
        })
        this.getDataTable()
        this.isLoading = false
      } catch(err) {
        console.log(err);
        this.isLoading = false
      }
    },
    cekStatus(data) {

    },
    formatUang(data) {
      // console.log(data);
			let uang = "";
			data = data.toString();
			for (let i = 0; i < data.length; i++) {
				if ((data.length - i) % 3 == 0 && i !== 0) {
				uang += `.${data[i]}`;
				} else {
				uang += data[i];
				}
			}
      return uang
    },

    async deleteData(id) {
      
      try {
        
        let data =await  axios.delete(`/user/group/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.getDataTable()

      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
          Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
        }     
      } catch(err) {
        
        Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },

    cekMonth(x) {
      let month = '-'
      month = this.months.find(e => e.id == x).name
      return month
    }
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }

.centered-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#mainModal {
  
  align-items: center;

}

</style>