<template>
  <div class="mainPage">
    <p id="headPage">MUTASI KAS</p>
    <div class="cardRow">
      <div class="inRow">
        <div class="card" id="startBalance">
          <p id="titleCard">Saldo Awal</p>
          <p id="amountCard">{{formatUang(totalBalance.startBalance)}}</p>
        </div>
        <div class="card" id="inCard">
          <p id="titleCard">Kas Masuk</p>
          <p id="amountCard">{{formatUang(totalBalance.addDebt)}}</p>
        </div>
        <div class="card" id="outCard">
          <p id="titleCard">Kas Keluar</p>
          <p id="amountCard">{{formatUang(totalBalance.payDebt)}}</p>
        </div>
        <div class="card" id="balanceCard">
          <p id="titleCard">Saldo Akhir</p>
          <p id="amountCard">{{formatUang(totalBalance.endBalance)}}</p>
        </div>
      </div>
    </div>
    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">
      </div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle" >Tanggal Mulai</p>
          <b-input type="date"  v-model="startDate"  @change="this.getVoucer"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="endDate"  @change="this.getVoucer"></b-input>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Jenis Voucer</p>
          <b-select v-model="findType" @change="this.getVoucer">
            <option :value=1> Pemasukan </option>
            <option :value=2> Pengeluaran </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Periode</p>
          <b-select v-model="findPeriod" @change="this.getGroup">
            <option v-for="e in this.periods" :key="e.id" :value="e.id">{{ e.year }} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Panitia/Pengurus</p>
          <b-select v-model="GroupId" @change="this.getGroup">
            <option v-for="e in this.groups" :key="e.id" :value="e.id">{{ e.name }} </option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input type="text" v-model="keyword"  @change="this.getVoucer"></b-input>
        </div>

      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()">Tambah</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
      <button @click.prevent="printCsv()" id="printer">
          <img src="../assets/printer.png" id="printer" alt="">
      </button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>#</th>
            <th>Panitia/Pengurus</th>
            <th>Tanggal</th>
            <th>No.Reff</th>
            <th>Akun</th>
            <th>Jenis</th>
            <th>Keterangan</th>
            <th>Jumlah</th>
            <th colspan="4">Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(e,index) in this.voucers" :key="e.id"> 
            <td>{{ index+1 }}</td>
            <td>{{ e.Group.name }}</td>
            <td>{{formatDate(e.date)}} </td>
            <td> {{e.refNo}} </td>
            <td> {{e.SubMataAnggaran.name}} </td>
            <!-- <td> {{e.SubMataAnggaran}} </td> -->
            <td v-if="e.type == 1" class="text-center"> <img id="status" src="../assets/masuk.png" alt=""> </td>
            <td v-if="e.type == 2" class="text-center"> <img id="status" src="../assets/keluar.png" alt=""> </td>
            <td> {{e.notes}}  <img v-if="e.isEdit" id="edited" src="../assets/edited.png" alt=""> </td>
            <td class="uang"> {{ formatUang(e.amount)}} </td>
            <!-- <td class="text-center">
              <button @click.prevent="changeStatus(e)">
                <img v-if="e.status" src="../assets/check.png" alt="">
                <img v-if="!e.status" src="../assets/wrong.png" alt="">
              </button>
            </td> -->
            <td id="action" class="text-center">
              <button @click.prevent="setPrintData(e)" title="Print" >
                <img src="../assets/printer.png" alt="">
              </button>
              <button @click.prevent="showEdit(e)" title="Edit" >
                <img src="../assets/edit.png" alt="">
              </button>
              <button @click.prevent="confirmDelete(e.id)" title="Hapus">
                <img src="../assets/trash.png" alt="">
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
  <div class="modal" id="mainModal">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Voucer</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editVoucer() : addVoucer()">
          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Tanggal</p>
              <b-input v-model="date" type="date" required></b-input>
            </div>
            <div class="inCol">
              <p id="titleModal">Jenis Voucer</p>
              <b-select v-model="type" required>
                <option value="1">Pemasukan</option>
                <option value="2">Pengeluaran</option>
              </b-select>
            </div>
            <!-- <div class="inCol">
              <p id="titleModal">CHEQUE/MCM</p>
              <b-input class="text-right" v-model="chequeNumber" type="text" @input="currencyNumber($event)"  required></b-input>
            </div> -->
            <div class="inCol">
              <p id="titleModal">Akun</p>
              <div @click="toggleDropdown" class="dropdown-header">
                {{ selected.name || "klik untuk mencari" }}
              </div>
              <div v-if="open" class="dropdown">
                <!-- Input berada di dalam dropdown untuk pencarian -->
                <input
                  type="text"
                  v-model="search"
                  placeholder="Type to search..."
                  class="dropdown-search"
                />
                <div
                  v-for="option in filteredOptions"
                  :key="option.id"
                  @click="select(option)"
                  class="dropdown-item"
                >
                  {{ option.name }} - {{ option.MataAnggaran.name }}
                </div>
              </div>
            </div>
            <div class="inCol">
              <p id="titleModal">Jumlah</p>
              <b-input class="text-right" v-model="amount" type="text" @input="currencyNumber($event)"  required></b-input>
            </div>
          </div>
          <div class="inRow">
          </div>
          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Keterangan</p>
              <b-input v-model="notes" name="detail" type="text" required></b-input>
            </div>
          </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </form>
      </div>
    </div>
  </div>
<!-- Print -->
  <div id="printArea">

    <div class="headPrint">
      <p id="titlePrint" v-if="this.print.type == 1">Voucer Penerimaan</p>
      <p id="titlePrint" v-if="this.print.type == 2">Voucer Pengeluaran</p>
      <p id="brancName"> {{ print.branchName }} </p>
      <p id="datePrint"> {{ print.date }} </p>
    </div>
    <hr>
    <div class="mainPrint">
      <table class="table table-borderless" id="printTable">
          <tr>
            <td id="keyTable">Nomor</td>
            <td id="titikDua">:</td>
            <td>{{ print.nomor }}</td>
          </tr>
          <tr>
            <td id="keyTable">Jumlah</td>
            <td id="titikDua">:</td>
            <td>Rp.{{ print.amount }}</td>
          </tr>
          <tr>
            <td id="keyTable">Keterangan</td>
            <td id="titikDua">:</td>
            <td>{{ print.notes}}</td>
          </tr>
      </table>
      <hr>
    </div>
    <div class="signArea">
      <div class="inRow">
        <div class="inCol">
          <p id="name">Kasir</p>
          <p>(............................)</p>
        </div>
        <div class="inCol" v-if="this.print.type == 1">
          <p id="name">Pemberi</p>
          <p>(............................)</p>
        </div>
        <div class="inCol" v-if="this.print.type == 2">
          <p id="name">Penerima</p>
          <p>(............................)</p>
        </div>
        <div class="inCol">
          <p id="name">Akuntansi</p>
          <p>(............................)</p>
        </div>
        <div class="inCol">
          <p id="name">Direktur</p>
          <p>(............................)</p>
        </div>
      </div>
    </div>
    <hr>
  </div>
</div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
import html2pdf from "html2pdf.js";
export default {
  name: "Voucer",
  data() {
    return  {
      isEdit: false,
      id: 0,
      voucers: [],
      currentPage: 1,
      perPage: 40,
			totalPage : 1,
      isLoading: false,
      isEdit: false,
      totalBalance: {},
      KelompokMaId: 0,
      maks: [],
      homes:[],
      groups:[],
      // filter
      startDate: "",
      endDate: "",
      keyword: "",
      findType: '',
      findPeriod: 0,
      GroupId: 0,
      // modal
      BranchId: 0,
      amount: '',
      notes: "",
      status: 1,
      date: "",
      type: "",
      secondMak: 0,
      chequeNumber: '',

      // modal search
      selected: {},
      search: '',
      open: false,
      filteredOptions: [],

      // print
      print: {
        branchName: '',
        date: '',
        nomor: '',
        notes: '',
        amount: '',
        type: 0
      }

    }
  },
  created() {
    this.$store.dispatch("getPeriod")
    this.getVoucer()
  },
  watch:{
     async search(newSearch) {
      try {
        if(this.search.length < 2) return []
        let {data} = await axios({
          method: "GET",
          url: "/transaksi/mak",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            type: this.type,
            name: this.search.toLowerCase(),
          }
        })
        console.log(data, "-data");
        
        return this.filteredOptions = data
        
      } catch (err) {
        return  this.filteredOptions = []
      }
    }
  },
  computed: {
    role() {
      return localStorage.getItem("role");
    },
    periods() {
      return this.$store.state.periods
    },

  },  
  methods: {
    resetFilter() {
      this.findBranchId= 0
      this.findType= ""
      this.findStatus= ""
      this.startDate= ""
      this.endDate= ""
      this.currentPage= 1
      this.getVoucer()
    },
    showAdd() {
      // return alert("Yess")
      this.isEdit = false
      this.date = moment(new Date).format("YYYY-MM-DD")
      $('#mainModal').modal('show')
    },

    // modal serach
    toggleDropdown() {
      this.open = !this.open;
      if (!this.open) this.search = ""; // Reset search when closing dropdown
    },

    select(option) {
      this.selected = option;
      this.secondMak = option.id;
      this.open = false;
      this.search = option.text; // Set search to selected option text
    },
    closeDropdown() {
      this.open = false;
    },
    setPrintData(e) {
      this.print.branchName = e.Branch.branchName,
      this.print.date = moment(e.date).format('DD-MM-YYYY')
      this.print.nomor = e.refNo
      this.print.amount = this.formatUang(e.amount)
      this.print.notes= e.note
      this.print.type = e.type
      this.exportToPDF()
    },

    exportToPDF() {
      this.isLoading = true
      document.getElementById('printArea').style.display='block'
      html2pdf(document.getElementById('printArea'),  {
        margin: 1,
        filename: "Voucer.pdf",
      }).then(e => {
        document.getElementById('printArea').style.display='none'
        this.isLoading = false
      })
    },

    showEdit(e) {
      this.id = e.id
      this.isEdit = true
      $('#mainModal').modal('show')
      this.date=  e.date
      this.type=  e.type
      this.notes=  e.notes
      // this.secondMak= e.SubMataAnggaran.id
      this.chequeNumber= e.chequeNumber
      this.amount=  this.setEditAmount(e.amount)
    },

    currencyNumber(event, target) {
        console.log(event, "===================");
        let currentValue = event.trim().split('.').filter(e => e != '.' ).join('')

        console.log(currentValue, typeof currentValue," -----------");
        if( Number.isNaN(Number(currentValue))) {
          // this.amount = 10
          this.amount = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
        }
        // currentValue = this.amount
        this.amount = this.formatUang(currentValue)
        // currentValue = this.amount
        // this.amount = this.amount.toLocaleString('id-ID')
        console.log(this.amount, "++++++++++++++");
    },
    setEditAmount(currentValue) {
      let result = 0
      if( Number.isNaN(Number(currentValue))) {
          // this.amount = 10
          this.amount = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
        }
        // currentValue = this.amount
      result = this.formatUang(currentValue)
      return result 
    },


    async changeStatus(e) {
      
      try {
        let data2 = await axios({
          method: "put",
          url: `/transaksi/status/${e.id}`,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data berhasil diproses', 'success');
        this.getVoucer()
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    formatDate(date) {
      let result = ''
      if(date) return moment(date).format("DD-MM-YYYY")
    },

    formatUang(data) {
      // console.log(data);
        
      let uang = "";
        if(data) {
          data = data.toString();
          for (let i = 0; i < data.length; i++) {
            if ((data.length - i) % 3 == 0 && i !== 0) {
            uang += `.${data[i]}`;
            } else {
            uang += data[i];
            }
          }
        }
        return uang
    },
    async getVoucer() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/transaksi",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            page: this.currentPage,
            limit: this.perPage,
            keyword : this.keyword,
            type : this.findType,
            PeriodId: this.findPeriod,
            GroupId: this.GroupId,
          }
        })
        console.log(data);
        this.voucers = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
        this.totalBalance = data.totalBalance
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async getGroup() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/user/group",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            PeriodId: this.findPeriod,
          }
        })
        this.groups = data
        this.getVoucer()
        // this.totalBalance = data.totalBalance
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },


    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/transaksi/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            keyword: this.keyword,
            BranchId: this.findBranchId,
            type: this.findType, 
            status: this.findStatus, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'Voucer',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading= false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    async addVoucer() {

      try {
        let status = null
        if(this.type == 2) {
          status = this.status
        }

        let amount = this.amount.trim().split('.').filter(e => e != '.' ).join('')
        let data =  {
          date:  this.date,
          type:  this.type,
          notes:  this.notes,
          secondMak: this.secondMak,
          chequeNumber: this.chequeNumber,
          amount:  amount,
        }

        let data2 = await axios({
          method: "POST",
          url: "/transaksi",
          data: data,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        $('#mainModal').modal('hide')
        this.chequeNumber= ''
        this.note= ''
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        this.getVoucer()
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },

    async editVoucer() {

      try {
        // return alert("Yes")
        let payload =  {
          secondMak: this.secondMak,
          chequeNumber: this.chequeNumber,
          date:  this.date,
          status:  this.status,
          type:  this.type,
          notes:  this.notes,
          amount:  this.amount.trim().split('.').filter(e => e != '.' ).join(''),
          isOpen : false
        }

        let data2 = await axios({
          method: "put",
          url: `/transaksi/${this.id}`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        $('#mainModal').modal('hide')
        this.getVoucer()
        this.BranchId=  0
        this.date=  ""
        this.status=  ""
        this.type=  ""
        this.note=  ""
        this.OrderId=  ""
        this.amount=  0
        this.id=  0
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    }, 

    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/transaksi/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.getVoucer()

      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getVoucer()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    cekMak(id) {
      return this.maks.find(e => e.id == id).name
    },
    cekHome(id) {
      let name = ''
      if(+id) return this.homes.find(e => e.id == id).name
      return name
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getVoucer()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getVoucer()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }

.centered-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#mainModal {
  
  align-items: center;

}

/* dropdown */
.dropdown-container {
  position: relative;
  width: 300px;
}
.dropdown-header {
  padding: 5px;
  text-align: left;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: #fff;
}
.dropdown {
  font-size: 12px;
  border: 1px solid #ccc;
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  background-color: white;
  width: 20rem;
  z-index: 1;
  margin-top: 50px;
  padding: 5px;
}
.dropdown-search {

  padding: 3px;
  box-sizing: border-box;
  border: 1px solid #ddd;
}
.dropdown-item {
  padding: 10px;
  cursor: pointer;
  text-align: left;

}
.dropdown-item:hover {
  background-color: #eee;
}




</style>