import { render, staticRenderFns } from "./Voucer.vue?vue&type=template&id=5bfdc358&scoped=true"
import script from "./Voucer.vue?vue&type=script&lang=js"
export * from "./Voucer.vue?vue&type=script&lang=js"
import style0 from "./Voucer.vue?vue&type=style&index=0&id=5bfdc358&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bfdc358",
  null
  
)

export default component.exports